import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Pagination, Row } from 'react-bootstrap'
import { CardBlog } from '../Components/Card/Card'

const BlogDetail = () => {


    const dataBlog = [
        {
            img: "./../images/blg (8).jpg",
            category: "Email Marketing",
            by: "Bilih Team",
            title: "Optimizing Your Email Sender Reputation for Better Deliverability",
            type: "small"
        },
        {
            img: "./../images/blg (1).jpg",
            category: "Email Marketing",
            by: "bilih team",
            title: "How to Create Personalized Email Campaigns",
            type: "small"
        },
        {
            img: "./../images/blg (2).jpg",
            category: "Email Marketing",
            by: "bilih team",
            title: "5 Tips for Writing Compelling Email Subject Lines",
            type: "small"
        },
        {
            img: "./../images/blg (3).jpg",
            category: "Email Marketing",
            by: "bilih team",
            title: "Streamlining Your Marketing Efforts",
            type: "small"
        },
        {
            img: "./../images/blg (4).jpg",
            category: "Email Marketing",
            by: "bilih team",
            title: "Building a Strong Email Subscriber List",
            type: "small"
        },
        {
            img: "./../images/blg (5).jpg",
            category: "Email Marketing",
            by: "bilih team",
            title: "5 Tips for Writing Compelling Email Subject Lines",
            type: "small"
        },
    ]
    return (
        <Fragment>
            <section className='pt-0'>
                <Container>
                    <img src="./../images/Image.png" className='w-full h-[250px] md:h-[300px] lg:h-[400px] xl:h-[500px] object-cover mb-6' alt="" />

                    <Row>
                        <Col className='mb-4 md:mb-0' md={7} lg={8}>
                            <div className="flex items-center gap-3 mb-3">
                                <div className="text__14 px-4 py-2 rounded-full border border-solid border-[#F5F5F5]">Email Marketing</div>
                                <p className='text__16'>by Bilih Team</p>
                            </div>

                            <h2 className='mb-3 font-medium text__48'>5 Tips for Writing Compelling Email Subject Lines</h2>

                            <p className='mb-3 font-medium text__20'>Expanding a business is an exciting but challenging process that requires careful planning and consideration of several key factors. Here are some important things to keep in mind when expanding your business:</p>

                            <div className="mb-3">
                                <p className='mb-2 font-medium text__18'>1. Market Research</p>
                                <p className='mb-2 font-medium text__18'>Conducting market research is crucial to understand the demand for your product or service in the target market. Identify the target audience, evaluate the competition, and assess the overall market conditions.</p>
                            </div>
                            <div className="mb-3">
                                <p className='mb-2 font-medium text__18'>2. Scalability</p>
                                <p className='mb-2 font-medium text__18'>Consider whether your business is scalable, that is, if it can grow without a significant increase in cost. Will the current staff and infrastructure support the expansion, or will you need to invest in additional resources?</p>
                            </div>
                            <div className="mb-3">
                                <p className='mb-2 font-medium text__18'>3. Legal and Regulatory Compliance</p>
                                <p className='mb-2 font-medium text__18'>Depending on the type of expansion, legal and regulatory requirements may change. Ensure you are complying with local laws and regulations, including permits, licenses, and taxes.</p>
                            </div>
                            <div className="mb-3">
                                <p className='mb-2 font-medium text__18'>4. Human Resources</p>
                                <p className='mb-2 font-medium text__18'>Determine if your current workforce is sufficient to manage the expansion or if you need to hire additional staff. Develop a plan for staff training and ensure the company culture is maintained during the expansion.</p>
                            </div>
                            <div className="mb-3">
                                <p className='mb-2 font-medium text__18'>5. Marketing Strategy</p>
                                <p className='mb-2 font-medium text__18'>Develop a marketing plan that includes branding, pricing, and distribution strategies for the new market. A well-developed marketing plan will ensure the expansion's success and attract new customers.</p>
                            </div>
                            <div className="mb-3">
                                <p className='mb-2 font-medium text__18'>6. Technology and Infrastructure</p>
                                <p className='mb-2 font-medium text__18'>Evaluate if your current technology and infrastructure are adequate for the expansion. Consider investing in technology, equipment, or software to improve productivity and efficiency.</p>
                            </div>
                            <div className="mb-3">
                                <p className='mb-2 font-medium text__18'>7. Risk Assessment</p>
                                <p className='mb-2 font-medium text__18'>Conduct a thorough risk assessment to identify potential challenges and develop contingency plans. Identify the risks that could derail the expansion and put measures in place to mitigate them.</p>
                                <p className='mb-2 font-medium text__18'>xpanding a business is an exciting but challenging process that requires careful planning and consideration of several key factors. Here are some important things to keep in mind when expanding your business:</p>
                            </div>

                            <hr className='my-4 border-[#E5E5E5]' />

                            <div className="flex items-center gap-3">
                                <img src="./../images/av (6).png" className='w-[112px] h-[112px] object-cover rounded-full' alt="" />
                                <div className="">
                                    <h5 className='font-medium text__18'>Mathias Abdissa</h5>
                                    <p className='text__16 text-[#1C1C1E] mb-3'>Author</p>

                                    <div className="flex items-center gap-2">
                                        <a href="https://www.linkedin.com/in/mathiasabdissa/">
                                            <img src="./../images/ss (1).svg" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={5} lg={4}>
                            <h4 className='mb-3 font-medium text__24'>Popular Article</h4>


                            {
                                dataBlog.map((obj, i) => {
                                    if (i < 3) {
                                        return <Fragment>
                                            <div className="w-full">
                                                <div className="flex items-center gap-3 mb-2">
                                                    <div className="text__14 px-4 py-2 rounded-full border border-solid border-[#F5F5F5]">{obj.category}</div>
                                                    <p className='text__16'>by {obj.by}</p>
                                                </div>
                                                <h5 className='font-semibold text__18'>{obj.title}</h5>
                                            </div>
                                            <hr className='my-6 !border-[#E5E5E5]' />
                                        </Fragment>
                                    }
                                })
                            }


                            <h4 className='mb-3 font-medium text__24'>Don't want to miss anything?</h4>

                            <h5 className='mb-3 font-medium text__18'>Newsletter</h5>
                            <div className="flex items-center gap-3">
                                <input type="text" className='w-full h-[46px] border-l-0 border-b-[1px] border-Mblack text__14 outline-none focus:outline-none active:outline-none' placeholder='Enter your email address' />
                                <button className='font-medium text-[14px] bg-Mgreen text-Mblack px-4 py-2 rounded-full'>Subscribe</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className='pt-0 xl:!pt-[80px]'>
                <Container>
                    <h2 className='mb-10 font-medium text__48'>Related Blog</h2>

                    <Row>
                        {
                            dataBlog.map((obj, i) => {
                                if (i >= 3) {
                                    return <Col md={6} lg={4} className='mb-4'><CardBlog data={obj} /></Col>
                                }
                            })
                        }
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default BlogDetail
