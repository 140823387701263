import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Pagination, Row } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'
import { CardBlog } from '../Components/Card/Card'
import GetStarted from '../Components/Pages/GetStarted'

const Blog = () => {
    const dataBlog = {
        large: [
            {
                img: "./../images/blg (6).jpg",
                category: "Social Marketing",
                by: "Bilih Team",
                title: "How to stand out on Social Medias?",
                type: "large",
                description: "In today's digital age, the realm of social media serves as a bustling marketplace of ideas, where individuals and brands alike vie for attention and relevance. With each platform offering a stage for self-expression and promotion, the question of how to stand out looms large, demanding strategic thinking and creativity. Whether you're an aspiring influencer, a burgeoning business, or a seasoned content creator, the journey to prominence on social media requires a nuanced approach, blending authenticity with innovation. Join us as we explore the essential steps to rise above the noise, carve out your niche, and leave a lasting impression in the ever-evolving landscape of social media.",
                link: "https://www.linkedin.com/feed/update/urn:li:activity:7180837972399841280"
            }
            // {
            //     img: "./../images/blg (7).jpg",
            //     category: "Email Marketing",
            //     by: "Bilih Team",
            //     title: "5 Tips for Writing Compelling Email Subject Lines",
            //     type: "large",
            //     description: "working while progressing",
            //     link: ""

            // },
        ],
        small: [
            // {
            //     img: "./../images/blg (8).jpg",
            //     category: "Design",
            //     by: "bilih team",
            //     title: "Optimizing Your Email Sender Reputation for Better Deliverability",
            //     type: "small",
            //     description: "working while progressing",
            //     link: ""


            // },
            // {
            //     img: "./../images/blg (1).jpg",
            //     category: "Design",
            //     by: "bilih team",
            //     title: "How to Create Personalized Email Campaigns",
            //     type: "small",
            //     description: "working while progressing",
            //     link: ""

            // },
            // {
            //     img: "./../images/blg (2).jpg",
            //     category: "Email Marketing",
            //     by: "bilih team",
            //     title: "5 Tips for Writing Compelling Email Subject Lines",
            //     type: "small",
            //     description: "working while progressing working while progressingworking while progressingworking while progressingworking while progressingworking while progressingworking while progressing working while progressing working while progressingworking while progressingworking while progressingworking while progressingworking while progressingworking while progressing"

            // },
            // {
            //     img: "./../images/blg (3).jpg",
            //     category: "Design",
            //     by: "bilih team",
            //     title: "Streamlining Your Marketing Efforts",
            //     type: "small",
            //     description: "working while progressing"

            // },
            // {
            //     img: "./../images/blg (4).jpg",
            //     category: "Email Marketing",
            //     by: "bilih team",
            //     title: "Building a Strong Email Subscriber List",
            //     type: "small",
            //     description: "working while progressing",
            //     link: ""

            // },
            // {
            //     img: "./../images/blg (5).jpg",
            //     category: "Email Marketing",
            //     by: "bilih team",
            //     title: "5 Tips for Writing Compelling Email Subject Lines",
            //     type: "small",
            //     description: "working while progressing",
            //     link: ""

            // },
        ]
    }
    return (
        <Fragment>
            <HeadTitle text={"Blog, tips and resources <br class='sm:block hidden' /> about <span class='text-Mblue'>bilih.</span>"} />

            <section className='pb-0 md:!pb-[20px]'>
                <Container>
                    <Row>
                        {
                            dataBlog.large.map((obj) => {
                                return <Col className='mb-4 md:mb-0' md={6}><CardBlog data={obj} /></Col>
                            })
                        }
                    </Row>
                </Container>
            </section>

            <section className='pt-0 md:!pt-[80px]'>
                <Container>
                    <div className="flex items-center justify-between mb-10">
                        <div className=''>
                            <h2 className='font-medium text-[18px] sm:text-[24px] md:text-[28px] lg:text-[32px] xl:text-[48px] shrink-0'>Lets grow together</h2>
                            <p className='font-normal'>Here some of our works that we worked with different clients.</p>
                        </div>

                        <div className="flex items-center gap-2">
                            <p className='text__16 text-[#737373] hidden md:block'>Categories</p>
                            <Form.Select aria-label="Default select example" className='font-medium text__16  h-[45px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#E5E5E5] focus:border-[#E5E5E5] shrink-0 w-auto  focus:!pr-4 min-w-[160px] md:!min-w-[190px]'>
                                <option>Email Marketing</option>
                                <option>Design</option>
                                <option value="2">Development</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </div>
                    </div>

                    <Row className='w-full h-ful'>
                        {
                            dataBlog.small.map((obj) => {
                                return <Col md={6} lg={4} className='mb-4'><CardBlog data={obj} /></Col>
                            })
                        }
                        <div className='w-full '>
                            <h1 className='text-4xl font-medium text-center'>We are coming to with it <span class='text-Mblue'>Very Soon.</span></h1>

                        </div>
                    </Row>
                </Container>
            </section>

            <GetStarted title="Get in touch with us!" sub="Connect with us today and discover how we can collaborate to achieve your goals." btnTitle="Work with us" btnLink="/contact" />
        </Fragment>
    )
}

export default Blog
