import React from 'react'
import { Fragment } from 'react'
import { Link, NavLink, Route } from 'react-router-dom'

export const CardBlog = (props) => {
    return (
        <Fragment>
            <Link to={props.data.link} target='_blank'>
                <img src={props.data.img} className={'w-full object-cover rounded-[16px] mb-3 ' + (props.data.type == "large" ? "h-[250px] lg:h-[350px]" : "h-[270px]")} alt="" />
                <div className="flex items-center gap-3 mb-3">
                    <div className="text__14 px-4 py-2 rounded-full border border-solid border-[#F5F5F5]">{props.data.category}</div>
                    <p className='text__16'>by {props.data.by}</p>
                </div>
                <h3 className={'font-medium ' + (props.data.type == "large" ? "text__32" : "text__24")}>{props.data.title}</h3>
                <p className=' text__12 line-clamp-3' >{props.data.description}</p>
            </Link>
        </Fragment>
    )
}
