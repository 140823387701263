import React, { Fragment } from 'react'
import { Container } from 'react-bootstrap'
import { Outlet } from "react-router-dom"

const AuthLayout = () => {
    return (
        <Fragment>
            <div className='relative grid w-full min-h-screen grid-cols-1 grid-rows-1 overflow-hidden bg-Mwhite md:grid-cols-2'>
                <div className='relative h-full bg-Mblue hidden md:!block'>
                    <img src="./../images/ghfhfgj.png" className='absolute top-0 left-0 object-cover object-top w-full h-full' alt="" />
                    s                </div>
                <div className='relative h-full'>
                    <Container className='flex items-center justify-center w-full h-full'>
                        <div className="w-full py-6 md:px-6 lg:px-10">
                            <Outlet />
                        </div>
                    </Container>
                </div>
            </div>
        </Fragment>
    )
}

export default AuthLayout
