import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const YouDo = () => {
    return (
        <section>
            <Container>
                <div className="mb-10 text-center">
                    <h2 className='mb-2 font-medium text__48'>Our Services</h2>
                    <p className='text__16'>We provide different services for business looking to improve <br className='hidden sm:block' />   their online value <span className='text-xl font-semibold text-Mblue'>&</span>  discover Digital Growth.</p>
                </div>

                <Row>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4}>
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full hover:text-Mblue">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mblue mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/favorite-chart.svg" alt="" />
                            </div>

                            <h4 className='mb-3 font-medium text__24'>Social Media Marketing and Paid Ads</h4>
                            <p className='text__16'>We help companies increase brand exposure and broaden customer reach by providing our social media marketing services that include, content creation, graphics design , creative directions, video and photo production including an outstanding social media look and design.</p>
                        </div>
                    </Col>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4}>
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full hover:text-Mblue">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mblue mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/graph.svg" alt="" />
                            </div>

                            <h4 className='mb-3 font-medium text__24'>Branding & Creative Design</h4>
                            <p className='text__16'>We create eye-catching and professional graphic design work for your business, such as logos, flyers, brochures, banners, posters, and more. Which can help you grow your business and communicate your message and vission effectively and aesthetically.</p>
                        </div>
                    </Col>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4}>
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full hover:text-Mblue ">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mblue mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/3dcube.svg" alt="" />
                            </div>

                            <h4 className='mb-3 font-medium text__24'>Web & App Development​</h4>
                            <p className='text__16'>If you have an idea that can have a digital solution that can be realized by a web or a mobile application software, We provide custom website and app development for our clients that is backed with the latest cutting edge technologies and designed to your specific needs.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4}>
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full hover:text-Mblue">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mblue mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/favorite-chart.svg" alt="" />
                            </div>

                            <h4 className='mb-3 font-medium text__24'>SEO (Search Engine optimization)</h4>
                            <p className='text__16'>With SEO, your business wants to increase its visibility in search results on search engines like Google and Bing. You specifically focus on search results related to your company, products, services, or industry. We give topnotch search engine optimization services for our clients.</p>
                        </div>
                    </Col>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4}>
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full hover:text-Mblue">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mblue mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/graph.svg" alt="" />
                            </div>

                            <h4 className='mb-3 font-medium text__24'>Photography and Digital Art</h4>
                            <p className='text__16'>Market and sell your digital products and subscriptions with ConvertKit to drive higher conversions and save big on fees.</p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    )
}

export default YouDo
