import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const GetStarted = ({ title, sub, btnTitle, btnLink }) => {
    return (
        <section className='relative overflow-hidden bg-Mblue'>
            <Container className='relative z-[2]'>
                <div className="text-center text-Mwhite">
                    <h2 className='mb-2 font-medium text__48'>{title ? title : "Get with us."}</h2>
                    <p className='mb-8 text__16'>{sub ? sub : "Your journey begins with a simple click or call."}</p>
                    <NavLink to={btnLink ? btnLink : "/pricing"} className='inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>{btnTitle ? btnTitle : "Get in touch"}</NavLink>
                </div>
            </Container>
        </section>
    )
}

export default GetStarted
