import React, { Fragment, useState } from 'react'
import { Accordion, Carousel, Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { CarouselBrand } from '../Components/Carousel/CarouselBrand'
import PriceList from '../Components/Pages/PriceList'
import YouDo from '../Components/Pages/YouDo'
import GetStarted from '../Components/Pages/GetStarted'
import Check from ".././Images/check.svg"
import BilihGirl from "../Images/Bilih-main-image.png"
import { PiSealCheckFill } from "react-icons/pi";
import FloatingButton from '../Components/Icon/Floatingbutton'



const Homepage = () => {

    const dataUsedCreator = [
        {
            title: "Honest Guidance",
            desc: "We offer up our very best ideas with no agenda. We take the success of our clients and each other seriously and freely offer honest advice."
        },
        {
            title: "Dependable",
            desc: "We are client-focused. We set expectations and keep our promises. We go above and beyond."
        },
        {
            title: "Thoughtful Collabration",
            desc: "We work the same way with our customers as we do with each other. We collaborate as a team to discover and deploy the best solutions through an open-minded approach."
        },
        {
            title: "Creative Craftsmanship",
            desc: "We are proud of the work that we create. We always work to improve our skills so that we can provide the best solutions."
        },
    ]

    const [ToogleAccordion, setToogleAccordion] = useState("Coaches")

    const setToogleAccordionWrap = (e) => {
        if (e == ToogleAccordion) {
            setToogleAccordion("")
        } else {
            setToogleAccordion(e)
        }
    }
    return (
        <Fragment>
            {/* start:hero */}
            <section className='pt-8 pb-0 md:!pb-[80px] relative overflow-hidden'>
                <Container className='relative z-[2]'>
                    <Row>
                        <Col md={6}>
                            <h1 className='mb-4 font-medium text__64 '>Transforming Your <span className='font-semibold text-Mgreen'>Ideas</span> into <span className='font-semibold text-Mblue'>Digital Stories<span className='text-Mblue'>.</span></span></h1>
                            <p className='mb-8 text__16 lg:w-full md:w-1/3'>global digital service provider based in Addis, Ethiopia. <br className='hidden lg:block' /> with a passion for creativity and technology, <br className='hidden lg:block' /> commited to crafting tomorrow's digital <br />experiences today.</p>


                            <div className="grid grid-rows-3 gap-2 mt-4">
                                <div className="flex items-center gap-2">
                                    <PiSealCheckFill size={24} />
                                    <span className='font-medium text__20 '>Creative Design</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <PiSealCheckFill size={24} />
                                    <span className='font-medium text__20 '>Development</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <PiSealCheckFill size={24} />
                                    <span className='font-medium text__20 '>Marketing</span>
                                </div>
                            </div>
                            <NavLink to="/services" className='inline-block cursor-pointer font-medium text__16 text-Mwhite bg-Mblue !border-Mgreen btnClass mt-6' >Our Services</NavLink>
                        </Col>
                    </Row>
                </Container>
                <img src={BilihGirl} className='mt-6 md:mt-0 ml-auto md:absolute md:right-0 md:bottom-0 xl:bottom-auto xl:top-0 md:top-4 w-[700px] lg:w-[600px] xl:w-[750px] md:w-[550px] pr-5' alt="Bilih Digital Girl" />


            </section>
            {/* end:hero */}

            <YouDo />

            <section>
                <Container>

                    <Row>
                        <Col className='mb-4 md:mb-0' md={6}>
                            <div className="mb-10">
                                <h2 className='mb-2 font-medium text__48'>Over values</h2>
                                <p className='text__16'>Your favorite creators use ConvertKit to connect with their audience and earn a living online.</p>
                            </div>

                            <div className="flex flex-wrap gap-3">
                                {
                                    dataUsedCreator.map((obj) => {
                                        return <div className={"p-4 rounded-2xl w-full cursor-pointer " + (ToogleAccordion == obj.title ? "bg-[#FAFAFA]" : "border border-solid border-[#F5F5F5]")} onClick={() => setToogleAccordionWrap(obj.title)}>
                                            <div className="flex items-center justify-between">
                                                <h5 className='font-medium text__20'>{obj.title}</h5>
                                                <img src="./../images/majesticons_chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion == obj.title ? "rotate-180" : "")} alt="" />
                                            </div>
                                            <div className={"py-2 " + (ToogleAccordion == obj.title ? "block" : "hidden")}>
                                                <p className='text__16'>{obj.desc}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>

                        </Col>
                        <Col md={6} className='mt-auto text-right'>
                            <img src="./../images/img (1).png" alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col className='my-auto md:!order-1 order-2' md={6}>
                            <img src="./../images/fhdhfh.png" alt="" />
                        </Col>
                        <Col className='mb-4 md:my-auto order-1 md:!order-2' md={6}>
                            <h2 className='mb-2 font-medium text__48'>Grow your audience to grow your income </h2>
                            <p className='mb-8 text__16'>In today's digital landscape, success is often measured by the size and engagement of your audience. Whether you're a content creator, entrepreneur, or business owner, expanding your reach can directly impact your growth and income potential.</p>
                            <NavLink to="/contact" className='inline-block cursor-pointer font-medium text__16 text-Mwhite bg-Mblue !border-Mblue btnClass'>Work with us</NavLink>
                        </Col>
                    </Row>
                </Container>
            </section>


            {/* <section>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='mb-2 font-medium text__48'>Plans for every stage of your <br /> creator business</h2>
                    </div>

                    <PriceList />
                </Container>
            </section> */}

            {/* <section>
                <Container>
                    <Row>
                        <Col className='my-auto md:!order-1 order-2' md={6}>
                            <img src="./../images/content.png" alt="" />
                        </Col>
                        <Col className='mb-4 md:my-auto order-1 md:!order-2' md={6}>
                            <h2 className='mb-2 font-medium text__48'>Increase conversions with automated emails that feel personal</h2>
                            <p className='text__16'>Lorem ipsum dolor sit amet consectetur. Dui enim lacus cras pulvinar pretium. Neque eget habitant rutrum enim. Elementum ut suspendisse tellus feugiat sollicitudin tellus quisque suspendisse dictum. Sollicitudin orci quam consequat diam sit gravida nunc tempor.</p>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* <section className='bg-[#FAFAFA] overflow-hidden'>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='mb-2 font-medium text__48'>Trusted by</h2>
                        <p className='text__16'>Hear from our satisfied clients and learn how we've helped them take their <br /> businesses to new heights.</p>
                    </div>

                    <div className="flex flex-wrap justify-center gap-3 mb-10 lg:gap-6">
                        <img src="./../images/br (1).png" className='w-[9rem] lg:w-[10rem] h-fit xl:h-auto xl:w-auto' alt="" />
                        <img src="./../images/br (2).png" className='w-[9rem] lg:w-[10rem] h-fit xl:h-auto xl:w-auto' alt="" />
                        <img src="./../images/br (3).png" className='w-[9rem] lg:w-[10rem] h-fit xl:h-auto xl:w-auto' alt="" />
                        <img src="./../images/br (4).png" className='w-[9rem] lg:w-[10rem] h-fit xl:h-auto xl:w-auto' alt="" />
                    </div>
                </Container>
                <CarouselBrand />
            </section> */}

            <GetStarted title="Get in touch with us!" sub="Connect with us today and discover how we can collaborate to achieve your goals." btnLink="/contact" btnTitle="Work with us" />

        </Fragment >
    )
}

export default Homepage
