import React from 'react';
import { Link } from 'react-router-dom';
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { LiaTelegram } from "react-icons/lia";





const FloatingButton = () => {
    return (
        <div className='fixed flex flex-col gap-2 sm:gap-2 bottom-2 right-3 md:flex-row sm:flex-row sm:right-10 md:right-5 md:bottom-5'>
            <Link to="tel: +251924473333" target='_blank'>

                <button className="p-3 text-white rounded-full shadow-lg bg-Mblue">
                    <MdOutlinePhoneInTalk className='w-4 h-4 md:h-6 md:w-6 sm:h-8 sm:w-8 lg:h-8 lg:w-8' />
                </button>
            </Link>
            <Link to="https://t.me/bilihchereta" target='_blank'>
                <button className=" p-3 text-white bg-[#34B7F1] rounded-full shadow-lg" >
                    <LiaTelegram className='w-4 h-4 md:h-6 md:w-6 sm:h-8 sm:w-8 lg:h-8 lg:w-8' />
                </button>
            </Link>
        </div>

    );
};

export default FloatingButton;
