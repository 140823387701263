import React from 'react'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DownIcon } from '../Icon/Icon'
import { NavLink } from 'react-router-dom'

const PriceList = () => {
    const [ToogleAccordion, setToogleAccordion] = useState("Creator")

    const setToogleAccordionWrap = (e) => {
        if (e == ToogleAccordion) {
            setToogleAccordion("")
        } else {
            setToogleAccordion(e)
        }
    }
    return (
        <Row>
            <Col className='mb-4 md:!mb-0' md={4}>
                <div className="p-4 bg-[#FAFAFA] rounded-xl text-center">
                    <h5 className='font-medium text__24 mb-3'>Free</h5>
                    <h2 className='font-medium text__48'>$0<span className='text__18 mb-2'>/month</span></h2>
                    <p className='text__16'>For new creators building their list</p>

                    <NavLink to="/plans-details" className='my-4 inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>Sign Up Free</NavLink>

                    <div className='inline-block h-[1px] w-full bg-Mblack opacity-[12%] mb-4 hidden md:block'></div>

                    <div className="md:hidden flex items-center justify-between py-3 my-3 border-t border-b border-solid !border-[#1717171f] cursor-pointer" onClick={() => setToogleAccordionWrap("Free")}>
                        <h5 className='font-medium text__14'>See plan summary</h5>
                        <img src="./../images/majesticons_chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion == "Free" ? "rotate-180" : "")} alt="" />
                    </div>

                    <div className={ToogleAccordion == "Free" ? "block" : "hidden md:block"}>
                        <div className="flex justify-center text-center w-full flex-wrap gap-4 font-medium text__18">
                            <p className='w-full'>Unlimited landing pages</p>
                            <p className='w-full'>Unlimited forms</p>
                            <p className='w-full'>Hours response time</p>
                            <p className='w-full'>Unlimited broadcasts</p>
                            <p className='w-full'>Audience tagging and segmentation</p>
                            <p className='w-full'>Sell digital products & subscriptions</p>
                            <p className='w-full'>Community support</p>
                            <p className='w-full opacity-50'>Free migration from another tool</p>
                            <p className='w-full opacity-50'>Automated email sequences</p>
                            <p className='w-full opacity-50'>Visual automation builders</p>
                            <p className='w-full opacity-50'>Third-party integrations</p>
                            <p className='w-full opacity-50'>Additional team members</p>
                            <p className='w-full opacity-50'>Newsletter referral system</p>
                            <p className='w-full opacity-50'>Subscriber scoring</p>
                            <p className='w-full opacity-50'>Advanced reporting</p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className='mb-4 md:!mb-0' md={4}>
                <div className="p-4 bg-Mblue rounded-xl text-center text-Mwhite">
                    <h5 className='font-medium text__24 mb-3'>Creator</h5>
                    <h2 className='font-medium text__48'>$9<span className='text__18 mb-2'>/month</span></h2>
                    <p className='text__16'>For growing creators automating their work</p>

                    <NavLink to="/plans-details" className='my-4 inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>Sign Up Free</NavLink>

                    <div className='inline-block h-[1px] w-full bg-Mblack opacity-[12%] mb-4 hidden md:block'></div>

                    <div className="md:hidden flex items-center justify-between py-3 my-3 border-t border-b border-solid !border-[#ffffff1f] cursor-pointer" onClick={() => setToogleAccordionWrap("Creator")}>
                        <h5 className='font-medium text__14'>See plan summary</h5>
                        <div className={"transition-all duration-500 " + (ToogleAccordion == "Creator" ? "rotate-180" : "")}>
                            <DownIcon color={"white"} />
                        </div>
                    </div>

                    <div className={ToogleAccordion == "Creator" ? "block" : "hidden md:block"}>
                        <div className="flex justify-center text-center w-full flex-wrap gap-4 font-medium text__18">
                            <p className='w-full'>Unlimited landing pages</p>
                            <p className='w-full'>Unlimited forms</p>
                            <p className='w-full'>Hours response time</p>
                            <p className='w-full'>Unlimited broadcasts</p>
                            <p className='w-full'>Audience tagging and segmentation</p>
                            <p className='w-full'>Sell digital products & subscriptions</p>
                            <p className='w-full'>Community support</p>
                            <p className='w-full'>Free migration from another tool</p>
                            <p className='w-full'>Automated email sequences</p>
                            <p className='w-full'>Visual automation builders</p>
                            <p className='w-full'>Third-party integrations</p>
                            <p className='w-full'>Additional team members</p>
                            <p className='w-full opacity-50'>Newsletter referral system</p>
                            <p className='w-full opacity-50'>Subscriber scoring</p>
                            <p className='w-full opacity-50'>Advanced reporting</p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className='mb-4 md:!mb-0' md={4}>
                <div className="p-4 bg-[#FAFAFA] rounded-xl text-center">
                    <h5 className='font-medium text__24 mb-3'>Creator Pro</h5>
                    <h2 className='font-medium text__48'>$25<span className='text__18 mb-2'>/month</span></h2>
                    <p className='text__16'>For established creators scaling their business</p>

                    <NavLink to="/plans-details" className='my-4 inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>Sign Up Free</NavLink>

                    <div className='inline-block h-[1px] w-full bg-Mblack opacity-[12%] mb-4 hidden md:block'></div>

                    <div className="md:hidden flex items-center justify-between py-3 my-3 border-t border-b border-solid !border-[#1717171f] cursor-pointer" onClick={() => setToogleAccordionWrap("Creator Pro")}>
                        <h5 className='font-medium text__14'>See plan summary</h5>
                        <img src="./../images/majesticons_chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion == "Creator Pro" ? "rotate-180" : "")} alt="" />
                    </div>

                    <div className={ToogleAccordion == "Creator Pro" ? "block" : "hidden md:block"}>
                        <div className="flex justify-center text-center w-full flex-wrap gap-4 font-medium text__18">
                            <p className='w-full'>Unlimited landing pages</p>
                            <p className='w-full'>Unlimited forms</p>
                            <p className='w-full'>Hours response time</p>
                            <p className='w-full'>Unlimited broadcasts</p>
                            <p className='w-full'>Audience tagging and segmentation</p>
                            <p className='w-full'>Sell digital products & subscriptions</p>
                            <p className='w-full'>Community support</p>
                            <p className='w-full'>Free migration from another tool</p>
                            <p className='w-full'>Automated email sequences</p>
                            <p className='w-full'>Visual automation builders</p>
                            <p className='w-full'>Third-party integrations</p>
                            <p className='w-full'>Additional team members</p>
                            <p className='w-full'>Newsletter referral system</p>
                            <p className='w-full'>Subscriber scoring</p>
                            <p className='w-full'>Advanced reporting</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default PriceList
