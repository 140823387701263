import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'
import GetStarted from '../Components/Pages/GetStarted'
const About = () => {

    const dataTeam = [
        {
            img: "./../images/av (6).png",
            name: "Mathias Abdissa",
            job: "Founder & Product Developer",
            facebook: "#!",
            linkedin: "https://www.linkedin.com/in/mathiasabdissa/",
            twitter: "#!",
        },
        {
            img: "./../images/av (3).png",
            name: "Ephrem Abdissa ",
            job: "Founder & Creative Director",
            facebook: "#!",
            linkedin: "https://www.linkedin.com/in/ephrem-abdissa-/",
            twitter: "#!",
        },
    ]
    return (

        <Fragment>
            <HeadTitle text={"About Us"} />

            <section>
                <Container>
                    <Row>
                        <Col className='mb-4 md:my-auto' md={6}>
                            <img src="./../images/dfsdfsdf.png" alt="" />
                        </Col>
                        <Col className='my-auto' md={6}>
                            <h2 className='mb-2 font-medium text__48'>Dedicated to helping businesses achieve success</h2>
                            <p className='mb-3 text__16'>At our core, we're here to transform your digital presence. From email, SMS and Social Media marketing to comprehensive digital strategies, we've got the tools to propel your success. With intuitive design and expert development, we bring your brand to life online. Trust us as your ally in the digital realm and watch your online endeavors thrive.</p>
                            <p className='text__16'>With us as your digital ally, your online endeavors are not just simplified; they're elevated to unprecedented levels of effectiveness and efficiency. Experience the difference and witness your digital presence soar to new heights of engagement, conversion, and brand recognition</p>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section>
                <Container>

                    <Row>
                        <Col className='md:!order-1 order-2' md={6}>
                            <h2 className='mb-10 font-medium text__48'>Anything you need to do with your audience</h2>

                            <div className="flex flex-wrap gap-6">

                                <div className="flex items-center w-full gap-3 p-4 md:p-6 border border-solid border-[#E5E5E5] rounded-[24px] hover:bg-[#FAFAFA] hover:text-Mblue">
                                    <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                        <img src="./../images/favorite-chart.svg" alt="" />
                                    </div>
                                    <div className="">
                                        <h5 className='mb-2 font-medium text__24'>Grow</h5>
                                        <p className='text__18'>Build systems to grow your audience and reach across channels</p>
                                    </div>
                                </div>
                                <div className="flex items-center w-full gap-3 p-4 md:p-6 border border-solid border-[#E5E5E5] rounded-[24px] hover:bg-[#FAFAFA] hover:text-Mblue">
                                    <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                        <img src="./../images/share.svg" alt="" />
                                    </div>
                                    <div className="">
                                        <h5 className='mb-2 font-medium text__24'>Connect</h5>
                                        <p className='text__18'>Segment your audience and email with deliverability you can rely on</p>
                                    </div>
                                </div>
                                <div className="flex items-center w-full gap-3 p-4 md:p-6 border border-solid border-[#E5E5E5] rounded-[24px] hover:bg-[#FAFAFA] hover:text-Mblue">
                                    <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                        <img src="./../images/grammerly.svg" alt="" />
                                    </div>
                                    <div className="">
                                        <h5 className='mb-2 font-medium text__24'>Automate</h5>
                                        <p className='text__18'>Save time by putting your best campaigns on auto-pilot</p>
                                    </div>
                                </div>
                                <div className="flex items-center w-full gap-3 p-4 md:p-6 border border-solid border-[#E5E5E5] rounded-[24px] hover:bg-[#FAFAFA] hover:text-Mblue">
                                    <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                        <img src="./../images/dollar-circle.svg" alt="" />
                                    </div>
                                    <div className="">
                                        <h5 className='mb-2 font-medium text__24'>Monetize</h5>
                                        <p className='text__18'>Sell digital products and integrate additional platforms for sales insights</p>
                                    </div>
                                </div>

                            </div>

                        </Col>
                        <Col className='order-1 md:!order-2 text-right mb-4 md:mb-0 md:mt-auto' md={6}>
                            <img src="./../images/sfsdfgsdgd.png" alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='mb-2 font-medium text__48'>Build your product with the <br className='hidden md:bloxk' /> right team</h2>
                        <p className='text__16'>The team behind Bilih, where a family in blood with passion come together for one purpose<br className='hidden md:bloxk text-Mblue' />.</p>
                    </div>

                    <Row className='justify-center gap-y-4'>
                        {
                            dataTeam.map((obj) => {
                                return <Col className='col-6 ' md={4} lg={3}>
                                    <div className="p-4 text-center ">
                                        <img src={obj.img} className='mx-auto w-[88px] lg:w-[136px] w-[88px] lg:h-[136px] rounded-full object-cover' alt="" />

                                        <div className="my-4">
                                            <h4 className='mb-2 font-medium text__20'>{obj.name}</h4>
                                            <p className='text__14 text-[#737373]'>{obj.job}</p>
                                        </div>

                                        <div className="flex items-center justify-center gap-3">

                                            <a href={obj.linkedin} target="_blank">
                                                <img src="./../images/ss (1).svg" alt="" rel="Bilih Linkedin Contact " />
                                            </a>

                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>

            <GetStarted title="Get in touch with us!" sub="Connect with us today and discover how we can collaborate to achieve your goals." btnTitle="Work with us" btnLink="/contact" />


            {/* <section>
                <Container>
                    <h2 className='mb-10 font-medium text-center text__48'>Anything you need to do with your audience</h2>

                    <div className="flex flex-wrap items-center justify-center gap-x-2 md:gap-x-4">
                        <img src="./../images/brr (11).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (1).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (2).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (3).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (4).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (5).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (6).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (7).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (8).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (9).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (10).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                    </div>
                </Container>
            </section> */}
        </Fragment>
    )
}

export default About
