import React, { useState } from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import YouDo from '../Components/Pages/YouDo'
import GetStarted from '../Components/Pages/GetStarted'
import HeadTitle from '../Components/Pages/HeadTitle'
import { NavLink } from 'react-router-dom'

const Services = () => {
    return (
        <Fragment>
            <HeadTitle text={"Just the list of things <br class='hidden sm:block' /> we love to do and how <br /> we do it<span class='text-Mblue'>.</span>"} />

            <YouDo />

            <section>
                <Container>

                    <div className="grid justify-between grid-cols-1 grid-rows-4 gap-4 md:grid-cols-2 md:grid-rows-2">
                        <div>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3 '>01</h5>
                            <h2 className='mb-2 font-medium text__48'>Brand messaging and positioning
                            </h2>
                            <p className='text__16'>We at Bilih, we stand out as a beacon of innovation and purpose. Our seamless blend of cutting-edge technology and unwavering commitment to social impact distinguishes us in IT Industry.</p>
                            <NavLink to="/pricing" className='inline-block my-0 font-medium text__16 text-Mblue'></NavLink>
                            <img src="./../images/du (4).png" alt="" />
                        </div>
                        <div className='md:mt-[8rem]'>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>02</h5>
                            <h2 className='mb-2 font-medium text__48'>Digital PR</h2>
                            <p className='text__16'>We can help you create a strong PR (Public Relations) campaign that will grab your audience’s attention and help you stand out from competitors.</p>
                            <NavLink to="/pricing" className='inline-block my-0 font-medium text__16 text-Mblue'></NavLink>
                            <img src="./../images/du (3).png" alt="" />
                        </div>
                        <div>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>03</h5>
                            <h2 className='mb-2 font-medium text__48'>Grow your audience and reach</h2>
                            <p className='text__16'>Growth is all about experimentation and iterations. We make that process quick and flexible for you to spin up the pages you need to deliver all your important content and convert new audiences.</p>
                            <NavLink to="/pricing" className='inline-block my-0 font-medium text__16 text-Mblue'></NavLink>
                            <img src="./../images/du (1).png" alt="" />
                        </div>

                    </div>

                </Container>
            </section>

            <GetStarted title="Get in touch with us!" sub="Connect with us today and discover how we can collaborate to achieve your goals." btnLink="/contact" btnTitle="Work with us" />

        </Fragment>
    )
}

export default Services
