import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Pagination, Row } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'

const Terms = () => {
    return (
        <Fragment>
            <HeadTitle text={"Term & Conditioner"} sub={"Effective Date: January 22, 2024"} />

            <section>
                <Container>
                    <Row className='justify-center'>
                        <Col md={10}>

                            {/* <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>Services</h5>
                                <p className='text-[14px] lg:text-[16px]'>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the website (the "Service") operated by Bilih Digital. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p></div>*/}
                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>Privacy</h5>
                                <p className='text-[14px] lg:text-[16px]'>
                                    At Bilih Digital, we understand the importance of your privacy. This Privacy Policy outlines the type of information we collect, how we use it, and how we protect your personal information. Please read this policy carefully to understand how we collect, use, and disclose information.

                                </p>
                            </div>
                            {/* <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>Accounts</h5>
                                <p className='text-[14px] lg:text-[16px]'>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>Intellectual Property</h5>
                                <p className='text-[14px] lg:text-[16px]'>The Service and its original content, features, and functionality are and will remain the exclusive property of Bilih Digital and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Bilih Digital.</p>
                            </div> */}



                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default Terms
